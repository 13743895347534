<template>
  <fragment>
    <wx-data-table
      :headers="computedDataSourceColumns"
      :items="computedDataSources"
      :items-per-page="1000"
      :filterable="true"
      :sort-by="['id', 'measureType']"
      :sort-desc="[false, false]"
      :no-results-text="this.$t('common.noMatchingRecords')"
      :hide-default-footer="true"
      mobile-breakpoint="sm"
      class="wx-panel"
    >
      <template #[`item.productionUnitNames`]="{ item }">
        <v-chip small outlined v-for="(name, index) in item.productionUnitNames" :key="`reason-${index}`" class="mr-1">
          {{ name }}
        </v-chip>
      </template>
      <template #no-data>
        {{ $t("devices.noDataSources") }}
      </template>
    </wx-data-table>

    <wx-data-table
      :headers="computedLastMeasureColumns"
      :items="computedLastMeasures"
      :items-per-page="1000"
      :filterable="true"
      :sort-by="['id', 'measureType']"
      :sort-desc="[false, false]"
      :no-results-text="this.$t('common.noMatchingRecords')"
      :hide-default-footer="true"
      mobile-breakpoint="sm"
      class="wx-panel mt-10 mb-3"
    >
      <template #[`item.lastReceptionDateTime`]="{ item }">
        <span :class="getDateTimeCssClasses(item.lastReceptionTimestamp)">{{ item.lastReceptionDateTime }}</span>
      </template>
      <template #[`item.lastMeasureDateTime`]="{ item }">
        <span :class="getDateTimeCssClasses(item.lastMeasureTimestamp)">{{ item.lastMeasureDateTime }}</span>
      </template>
      <template #no-data>
        {{ $t("devices.noDataSources") }}
      </template>
    </wx-data-table>

    <div class="ml-4">
      <span class="wx-typo-sm font-weight-light">
        {{ $t("devices.status.lastCheck", { date: lastRefreshDate }) }}
      </span>
    </div>
    <div class="ml-4">
      <span class="wx-typo-sm font-weight-light">
        {{ $t("devices.status.timezone", { timezone: factoryTimezone }) }}
      </span>
    </div>

    <v-row>
      <v-col cols="12" class="mt-4">
        <wx-btn-standard color="primary" class="my-3" @click="toDevices()">
          <v-icon class="mr-2">mdi-arrow-left</v-icon>
          {{ $t("common.back") }}
        </wx-btn-standard>
      </v-col>
    </v-row>
  </fragment>
</template>

<script>
import * as TimeUtils from "@/store/TimeUtils";
import { DateTime } from "luxon";
import { DATE_TIME_FORMAT_NO_TIMEZONE } from "@/store/TimeUtils";
import { mapGetters } from "vuex";
import DataSourceService from "@/components/devices/DataSourceService";
import ErrorHandling from "@/components/ErrorHandling";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import WxDataTable from "@/components/ui/WxDataTable.vue";
import WxBtnStandard from "@/components/ui/WxBtnStandard.vue";
import RouteService from "@/router/RouteService";

export default {
  name: "DeviceStatus",
  components: { WxBtnStandard, WxDataTable },
  props: {
    deviceId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dataSourcesActivity: [],
      refreshLoop: null,
      lastRefreshDate: "",
    };
  },
  computed: {
    ...mapGetters("devicesAdmin", ["activeFactoryDataSources"]),
    ...mapGetters("navigation", ["activeFactory", "activeFactoryProductionUnits"]),
    computedDataSourceColumns() {
      return [
        { text: this.$t("devices.status.dataSourceId"), class: "col-device-id", align: "start", value: "id" },
        { text: this.$t("devices.status.measureType"), class: "col-ds", align: "start", value: "measureType" },
        { text: this.$t("devices.productionUnits"), class: "col-pu", value: "productionUnitNames" },
        { text: "", class: "col-actions", value: "actions", sortable: false },
      ];
    },
    computedDataSources() {
      let dataSources = this.activeFactoryDataSources.filter((ds) => ds.device_id === this.deviceId);
      return dataSources.map((ds) => {
        let productionUnitNames = [];
        ds.production_unit_ids.forEach((puId) => {
          const pu = this.activeFactoryProductionUnits.find((pu) => pu.id === puId);
          if (pu) {
            if (!productionUnitNames.includes(pu.name)) {
              productionUnitNames.push(pu.name);
            }
          }
        });
        return {
          id: ds.id,
          measureType: ds.measure_type,
          productionUnitNames: productionUnitNames,
        };
      });
    },
    computedLastMeasureColumns() {
      return [
        { text: this.$t("devices.status.dataSourceId"), class: "col-ds", align: "start", value: "dataSourceId" },
        {
          text: this.$t("devices.status.lastReceptionTime"),
          class: "col-last-reception-time",
          align: "start",
          value: "lastReceptionDateTime",
        },
        {
          text: this.$t("devices.status.lastMeasureTimestamp"),
          class: "col-last-timestamp",
          align: "start",
          value: "lastMeasureDateTime",
        },
        { text: this.$t("devices.status.lastMeasureValue"), class: "col-measure-value", value: "lastMeasureValue" },
      ];
    },
    computedLastMeasures() {
      if (this.activeFactory) {
        return this.dataSourcesActivity.map((ds) => ({
          dataSourceId: ds.id,
          lastReceptionTimestamp: ds.last_reception_time,
          lastReceptionDateTime: this.formatTime(ds.last_reception_time, this.activeFactory.timezone),
          lastMeasureTimestamp: ds.last_reception_time,
          lastMeasureDateTime: this.formatTime(ds.last_measure_timestamp, this.activeFactory.timezone),
          lastMeasureValue:
            ds.last_measure_value !== null && ds.last_measure_value !== undefined ? ds.last_measure_value : "-",
        }));
      }
      return [];
    },
    factoryTimezone() {
      if (this.activeFactory) {
        return this.activeFactory.timezone;
      }
      return "";
    },
  },
  methods: {
    toDevices() {
      this.$router.replace(RouteService.toDevices());
    },
    loadDataSourcesActivity() {
      DataSourceService.getDataSourcesActivity(this.deviceId)
        .then((httpResponse) => this.handleRetrievalResponse(httpResponse))
        .catch((error) => this.handleRetrievalError(error));
    },
    handleRetrievalResponse(httpResponse) {
      if (httpResponse.status === 200) {
        this.dataSourcesActivity = httpResponse.data;
        this.lastRefreshDate = this.formatTime(DateTime.now().toMillis(), this.activeFactory.timezone);
      }
    },
    handleRetrievalError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
    },
    getErrorMessage(code) {
      return this.$t("common.errors.default", { code: code });
    },
    getRefreshDelay() {
      return 10000; // 10 seconds
    },
    formatTime(timeMillis, timezone) {
      if (timeMillis) {
        return TimeUtils.fromEpochMillis(timeMillis, timezone, DATE_TIME_FORMAT_NO_TIMEZONE);
      } else {
        return "-";
      }
    },
    getDateTimeCssClasses(timestamp) {
      if (timestamp) {
        const diff = DateTime.utc().toMillis() - timestamp;
        if (diff > ProductionUnitService.getTimeWithoutMeasureToleranceMillis()) {
          return "error--text";
        }
        return "success--text";
      }
      return "error--text";
    },
  },
  mounted() {
    this.loadDataSourcesActivity();
    clearInterval(this.refreshLoop);
    this.refreshLoop = setInterval(() => this.loadDataSourcesActivity(), this.getRefreshDelay());
  },
  beforeDestroy() {
    clearInterval(this.refreshLoop);
  },
};
</script>

<style lang="scss" scoped>
.action-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;

  .v-input {
    max-width: 400px;
  }
}
::v-deep .col-ds {
  width: 25%;
}

::v-deep .col-last-reception-time {
  width: 25%;
}

::v-deep .col-last-timestamp {
  width: 30%;
}

::v-deep .col-measure-value {
  width: 20%;
}
</style>
